import React from 'react';
import { tn } from '../../../translation';

export const CVtemplate3 = ({ data, lang }: any) => {
  return (
    <div
      dir={lang === "ar" ? "rtl" : "ltr"}
      style={{
        display: 'flex',
        fontFamily: data?.font_type || 'Helvetica, sans-serif',
        color: '#333',
        maxWidth: '1000px',
        margin: '0 auto',
        backgroundColor: '#fff',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Sidebar Section */}
      <aside
        style={{
          backgroundColor: data?.cv_color || '#007acc',
          color: '#fff',
          width: '300px',
          padding: '30px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {/* Profile Image */}
        {data?.profile_picture && (
          <div
            style={{
              borderRadius: '50%',
              width: '150px',
              height: '150px',
              overflow: 'hidden',
              marginBottom: '20px',
              border: '4px solid #fff',
            }}
          >
            <img
              src={
                typeof data?.profile_picture === "string"
                  ? data?.profile_picture
                  : URL?.createObjectURL(data?.profile_picture)
              }
              alt="Profile"
              style={{ width: '100%', height: '100%', objectFit: 'cover',              backgroundColor: '#fff',
            }}
            />
          </div>
        )}

        {/* Contact Info */}
        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
          <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
            {data.first_name} {data.last_name}
          </h1>
          <p style={{ fontSize: '16px', fontStyle: 'italic', marginBottom: '20px' }}>
            {data.about}
          </p>
        </div>

        <div style={{ width: '100%', textAlign: 'center' }}>
          <p style={{ fontSize: '14px', marginBottom: '10px' }}>
            ✉️ <a href={`mailto:${data.email}`} style={{ color: '#fff', textDecoration: 'none' }}>{data.email}</a>
          </p>
          <p style={{ fontSize: '14px' }}>
            📞 <a href={`tel:${data.mobile}`} style={{ color: '#fff', textDecoration: 'none' }}>{data.mobile}</a>
          </p>
        </div>
      </aside>

      {/* Main Content Section */}
      <main
        style={{
          flexGrow: 1,
          padding: '40px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Experience Section */}
        <section>
          <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Experiences', lang)}</h2>
          </div>
          {data.experiences.map((experience, index) => (
            <div key={index} style={{ marginBottom: '25px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{experience.job_title}</h3>
                <p style={{ fontSize: '14px', color: '#999' }}>
                  {experience.start_date && new Date(experience.start_date).toLocaleDateString()}{" "}
                  -{" "}
                  {experience.end_end && new Date(experience.end_end).toLocaleDateString()}
                </p>
              </div>
              <p style={{ fontSize: '14px', color: '#666' }}>{experience.company} - {experience.city}, {experience.country}</p>
              <div
                dangerouslySetInnerHTML={{ __html: experience.description }}
                style={{ fontSize: '14px', color: '#444' }}
              ></div>
            </div>
          ))}
        </section>

        {/* Education Section */}
        <section>
          <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Education', lang)}</h2>
          </div>
          <div>
            <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{data.university}</h3>
            <p style={{ fontSize: '14px', color: '#666' }}>{data.major}</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', color: '#999' }}>
              <p>{tn("University number", lang)}: {data.university_number}</p>
              <p>{tn("GPA", lang)}: {data.GPA}/{data.GPA_out_of}</p>
            </div>
          </div>
          {data.education.map((education_, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{education_.university}</h3>
              <p style={{ fontSize: '14px', color: '#666' }}>{education_.major}</p>
              <p style={{ fontSize: '14px', color: '#999' }}>
                {education_.graduation_date && new Date(education_.graduation_date).toLocaleDateString()}
              </p>
              <p style={{ fontSize: '14px', color: '#999' }}>{education_.GPA}</p>
            </div>
          ))}
        </section>

        {/* Skills Section */}
        <section>
          <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Skills', lang)}</h2>
          </div>
          <ul style={{ fontSize: '14px', listStyleType: 'circle', padding: '0 20px', color: '#555' }}>
            {data.skills.map((skill, index) => (
              <li key={index} style={{ marginBottom: '8px' }}>
                <span style={{ fontWeight: '600' }}>{skill.skill}</span> - {skill.level}
              </li>
            ))}
          </ul>
        </section>

        {/* Certificates Section */}
        <section>
          <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Certificates and Courses', lang)}</h2>
          </div>
          {data.certificates.map((certificate, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <p style={{ fontSize: '14px', color: '#555' }}>
                <strong>{certificate.title}</strong> - {certificate.institute}
              </p>
              <p style={{ fontSize: '14px', color: '#999' }}>
                {certificate.date && new Date(certificate.date).toLocaleDateString()}
              </p>
            </div>
          ))}
        </section>

        {/* Languages Section */}
        <section>
          <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Languages', lang)}</h2>
          </div>
          <ul style={{ fontSize: '14px', paddingLeft: '20px', color: '#555' }}>
            {data.languages.map((language, index) => (
              <li key={index} style={{ marginBottom: '8px' }}>
                {language.language} - {language.level}
              </li>
            ))}
          </ul>
        </section>
      </main>
    </div>
  );
};

// Section title style
const sectionTitleStyle = (color: string) => ({
  fontWeight: 'bold',
  fontSize: '22px',
  color: color || '#007acc',
  borderBottom: `3px solid ${color || '#007acc'}`,
  paddingBottom: '5px',
  marginBottom: '20px',
});
