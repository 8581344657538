import { ViewChanger } from "../ViewChanger";
import MainHeaderApplicantWeb from "../web/components/MainHeaderApplicant";
import MainHeaderApplicantMobile from "../mobile/components/MainHeaderApplicant";
import { styled } from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";
import CVform from "./CVform";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import { pb } from "../../config";
import { containsArabicNumbers, convertArabicNumbersToEnglish, convertEnglishNumbersToArabic, fv } from "../../main_components/Functions";
import CVviewer from "./CVviewer";
import { useForm } from "@mantine/form";
import { useParams } from "react-router-dom";
import { MantineProvider, createEmotionCache } from "@mantine/core";
import rtlPlugin from "stylis-plugin-rtl";
import { CMS_ENDPOINT } from "../../constants";

const rtlCache = createEmotionCache({
  key: "mantine-rtl",
  stylisPlugins: [rtlPlugin],
});

function CVgenerator() {
  const {lang = "ar",id}= useParams();
 
 const userData = pb.authStore.model;

    const {locale,translation}:any = useContext(TranslationContext)
   
    const [phoneNumber, setPhoneNumber]: any = useState(
      userData?.mobile?.replace(userData?.country_mobile_number, "")
    );
  const [maxGPA, setMaxGPA] = useState(userData?.GPA_out_of);

  const [GPAfirst, setGPAfirst] = useState(
    lang == "en"
      ? userData?.GPA.toString()
      : convertEnglishNumbersToArabic(userData?.GPA.toString())
  );
  const form = useForm({
    initialValues:{
      "cv_template": 1,
      "cv_size": "A4",
      "cv_color": "#fd7e14",
      "font_type": "Tahoma",
      "case_study": fv(userData?.case_study,"case_study",translation)?.[lang],
      "university": fv(userData?.university,"university",translation)?.[lang],
      "major": fv(userData?.major,"major",translation)?.[lang],
      "GPA": userData?.GPA,
      "country_mobile_number": userData?.country_mobile_number,
      "graduation_year": fv(userData?.graduation_year,"graduation_year",translation)?.[lang],
      "semester": fv(userData?.semester,"semester",translation)?.[lang],
      "GPA_out_of": userData?.GPA_out_of,
      "degree": fv(userData?.degree,"degree",translation)?.[lang],
      "first_name": userData?.first_name,
      "last_name": userData?.last_name,
      "gender": fv(userData?.gender,"gender",translation)?.[lang],
      "mobile": userData?.mobile,
      "about": userData?.about,
      "email": userData?.email,
      "university_number":userData?.university_number,
      "experiences": [],
      "certificates": [],
      "skills": [],
      "education": [],
      "languages": [],
      "career_summary": "",
      "cv_title": "",
      "profile_picture": null,
        // "cv_size": "A4",
        // "cv_color": "#2A9D8F",
        // "case_study": "طالب",
        // "university": "جامعة الملك فهد للبترول والمعادن",
        // "major": "هندسة كهربائية",
        // "GPA": 3,
        // "country_mobile_number": "+966",
        // "graduation_year": "2023",
        // "semester": "الفصل الدراسي الثاني",
        // "GPA_out_of": "4",
        // "degree": "البكالوريوس",
        // "first_name": "عبد الرحمن",
        // "last_name": "محمد",
        // "gender": "ذكر",
        // "mobile": "+966123456789",
        // "about": "مهندس كهربائي شغوف بالتقنيات الحديثة والطاقة المتجددة، مع خبرة في تصميم الأنظمة الكهربائية وتحليل البيانات.",
        // "email": "abdelrahman.mohamed@example.com",
        // "university_number": "2021567890",
        // "experiences": [
        //     {
        //         "company": "شركة الكهرباء السعودية",
        //         "job_title": "متدرب هندسي",
        //         "start_date": "2023-06-01T21:00:00.000Z",
        //         "end_end": "2023-09-01T21:00:00.000Z",
        //         "country": "المملكة العربية السعودية",
        //         "city": "الدمام",
        //         "description": "<p>عملت على تحسين أداء الشبكات الكهربائية والتحليل باستخدام أنظمة SCADA</p>"
        //     },
        //     {
        //         "company": "شركة تقنيات الطاقة المتجددة",
        //         "job_title": "مهندس تصميم كهربائي",
        //         "start_date": "2024-01-10T21:00:00.000Z",
        //         "end_end": "2024-10-15T21:00:00.000Z",
        //         "country": "المملكة العربية السعودية",
        //         "city": "الرياض",
        //         "description": "<p>شاركت في تصميم وتنفيذ مشاريع الطاقة الشمسية والطاقة المتجددة</p>"
        //     }
        // ],
        // "certificates": [
        //     {
        //         "company": "",
        //         "title": "شهادة PMP",
        //         "institute": "معهد إدارة المشاريع",
        //         "date": "2024-10-04T21:00:00.000Z",
        //         "type": "شهادة"
        //     },
        //     {
        //         "company": "",
        //         "title": "دورة تحليل البيانات",
        //         "institute": "مركز تطوير البيانات",
        //         "date": "2024-05-01T21:00:00.000Z",
        //         "type": "دورة"
        //     }
        // ],
        // "skills": [
        //     {
        //         "company": "",
        //         "skill": "تحليل البيانات",
        //         "level": "متقدم"
        //     },
        //     {
        //         "company": "",
        //         "skill": "برمجة Python",
        //         "level": "خبير"
        //     }
        // ],
        // "education": [
        //     {
        //         "company": "",
        //         "university": "جامعة الملك فهد للبترول والمعادن",
        //         "major": "هندسة كهربائية",
        //         "graduation_date": "2023-06-01T21:00:00.000Z",
        //         "GPA": "3.75",
        //         "additional_information": "مشروع التخرج حول تحليل الشبكات الكهربائية"
        //     }
        // ],
        // "languages": [
        //     {
        //         "company": "",
        //         "language": "العربية",
        //         "level": "متقدم"
        //     },
        //     {
        //         "company": "",
        //         "language": "الإنجليزية",
        //         "level": "متقدم"
        //     }
        // ],
        // "career_summary": "<p>مهندس كهربائي ذو خبرة في تصميم الأنظمة الكهربائية وتحليل البيانات، يسعى للعمل في مشاريع الطاقة المتجددة والمستدامة.</p>",
        // "cv_title": "مهندس كهربائي",
        // "profile_picture": null,
        // "font": "Arial",
        // "font_type": "Tahoma"
    
  }
  ,
  
  });
  console.log("formform",form.values)
  useEffect(() => {
    if(id){
      (async () => {
        const record = await pb.collection('applicants_cv').getOne(id, {});
        form.setValues({
          ...record?.data,
          profile_picture:record?.profile_picture? `${CMS_ENDPOINT}/api/files/${record?.collectionName}/${record?.id}/${record?.profile_picture}?token=`:null
        });
      })()
     
    }
  }, []);
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(count + 1);
    if (count > 0) {
      form.setFieldValue("GPA", null);
      setGPAfirst("");
    }
  }, [maxGPA]);
  useEffect(() => {
    const formatted = convertArabicNumbersToEnglish(GPAfirst);
    const value: any = !isNaN(formatted) ? parseFloat(formatted) : null;
    if (value > 0) {
      if (value > maxGPA) {
        form.setFieldValue("GPA", maxGPA);
        setGPAfirst(
          !containsArabicNumbers(GPAfirst)
            ? maxGPA.toString()
            : convertEnglishNumbersToArabic(maxGPA.toString())
        );
      } else {
        form.setFieldValue("GPA", value);
      }
    } else {
      form.setFieldValue("GPA", null);
    }
  }, [GPAfirst]);

  useEffect(() => {
    // if (phoneNumber.length > 0) {
      form.setFieldValue("mobile", "");
    // }
  }, [form.values.country_mobile_number]);
  useEffect(() => {
    // if (phoneNumber.length > 0) {
      form.setFieldValue(
        "mobile",
        form.values.country_mobile_number + phoneNumber
      );
    // } else {
    //   form.setFieldValue("mobile", userData?.mobile);
    // }
  }, [phoneNumber]);
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      //@ts-ignore
      emotionCache={lang == "ar" && rtlCache}
      theme={{
        fontFamily: "IBM Plex Sans Arabic",
        primaryColor: "color1",
        // colorScheme: theme ? theme : "light",
        dir: lang == "ar" ? "rtl" : "ltr",
        colors: {
          color1: [
            "#f5ecfe",
            "#e6d3f9",
            "#cba2f4",
            "#b06ef1",
            "#9944ee",
            "#8a2aec",
            "#841eed",
            "#7114d3",
            "#650fbc",
            "#5708a6",
          ],
          color2: [
            "#e1fef8",
            "#d3f8ef",
            "#aaeddd",
            "#80e2ca",
            "#5cd9b9",
            "#44d3af",
            "#34d0aa",
            "#20b994",
            "#0ca483",
            "#008f6f",
          ],
          color3: [
            "#fff9e1",
            "#fff0cc",
            "#ffe09b",
            "#ffce64",
            "#ffc038",
            "#ffb61c",
            "#ffb209",
            "#e39c00",
            "#ca8a00",
            "#af7600",
          ],
          color4: [
            "#ffeae6",
            "#ffd3d0",
            "#fca49f",
            "#f8736b",
            "#f54a3f",
            "#f43023",
            "#f42114",
            "#da1309",
            "#c30a06",
            "#ab0001",
          ],
        },
      }}
    >
    <div
    dir={lang == "ar" ? "rtl" : "ltr"}
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      {/* <div style={{position: "absolute",top:"0",zIndex: "9996"}}> */}
      <ViewChanger web={<MainHeaderApplicantWeb lang={lang} />} mobile={<></>} />
      {/* </div> */}
      <Content className="max-md:flex max-md:flex-col ">
         <div className="w-[50%] h-full bg-[#BFBBC359] max-md:hidden">
          <CVviewer data={form.values} lang={lang} />
          </div>
       <CVform id={id} lang={lang} locale={locale} form={form} setGPAfirst={setGPAfirst} GPAfirst={GPAfirst}setMaxGPA={setMaxGPA} setPhoneNumber={setPhoneNumber}/>
       
      </Content>
    </div>
    </MantineProvider>
  );
}

const Content = styled.div`
width:  calc(100vw);
  height: calc(100vh - 84px);
  @media (max-width: 550px) {
    height: 100vh;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default CVgenerator;

const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 25 22"
    fill="none"
  >
    <path
      d="M16.8364 4.14751L20.5797 7.46828M7.24997 6.65788H3.31249C2.9644 6.65788 2.63056 6.78056 2.38442 6.99892C2.13828 7.21728 2 7.51344 2 7.82225V19.4659C2 19.7747 2.13828 20.0709 2.38442 20.2892C2.63056 20.5076 2.9644 20.6303 3.31249 20.6303H17.7499C18.098 20.6303 18.4318 20.5076 18.678 20.2892C18.9241 20.0709 19.0624 19.7747 19.0624 19.4659V14.2263M22.2242 2.6874C22.4701 2.90549 22.6652 3.16443 22.7983 3.44942C22.9314 3.73441 22.9999 4.03988 22.9999 4.34836C22.9999 4.65685 22.9314 4.96231 22.7983 5.24731C22.6652 5.5323 22.4701 5.79124 22.2242 6.00933L13.2415 13.9783L8.56246 14.8084L9.49827 10.6575L18.481 2.68856C18.7266 2.47028 19.0184 2.29712 19.3395 2.17897C19.6607 2.06082 20.0049 2 20.3526 2C20.7003 2 21.0445 2.06082 21.3657 2.17897C21.6868 2.29712 21.9786 2.47028 22.2242 2.68856V2.6874Z"
      stroke="#FFB004"
      stroke-width="2.62499"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
