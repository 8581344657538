import { useContext, useEffect, useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Anchor,
  Group,
  Burger,
  rem,
  Center,
  RingProgress,
  Text,
  Transition,
  ActionIcon,
  Popover,
} from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { MantineLogo } from "@mantine/ds";
import { IconArrowUpRight, IconBell } from "@tabler/icons-react";
import { CMS_ENDPOINT } from "../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../context/AppContext";
import { tn } from "../../../translation";
import { TranslationContext } from "../../../context/TranslationContext";
import { NotiPopup } from "../../../main_components/notifications/NotiPopup";

const HEADER_HEIGHT = rem(84);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 140000,
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`,
  },

  mainLink: {
    textTransform: "uppercase",
    fontSize: rem(13),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    padding: `${rem(7)} ${theme.spacing.sm}`,
    fontWeight: 700,
    borderBottom: `${rem(2)} solid transparent`,
    transition: "border-color 100ms ease, color 100ms ease",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },

  secondaryLink: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
    transition: "color 100ms ease",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },

  mainLinkActive: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottomColor:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 5 : 6],
  },
}));

interface LinkProps {
  label: string;
  link: string;
}
export default function MainHeaderApplicant({lang}:any) {
  const pb = new PocketBase(CMS_ENDPOINT);
  const { locale } = useContext(TranslationContext);
  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const { height, width } = useViewportSize();
  const [ProgressValue, setProgressValue] = useState(30);
  const userData = pb.authStore.model;
  const { reloader }:any = useContext(AppContext);
  //    useEffect(() => {
  //     pb.collection(pb.authStore.model?.collectionName).subscribe(pb.authStore.model.id, (e) => {
  //       pb.authStore.save(pb.authStore.token, e.record);
  //       setUserData(e.record);
  // });
  // return () => {
  //   pb.collection(pb.authStore.model?.collectionName).unsubscribe()
  // };
  //   }, [])

  useEffect(() => {
    // setUserData(pb.authStore.model)
    setProgressValue(
      Math.floor(
        (((userData?.mobile ? 1 : 0) +
          (userData?.university ? 1 : 0) +
          (userData?.major ? 1 : 0) +
          (userData?.GPA ? (userData?.GPA !== "0" ? 1 : 0) : 0) +
          (userData?.cv ? 1 : 0) +
          (userData?.about ? 1 : 0) +
          (userData?.graduation_year ? 1 : 0) +
          (userData?.semester ? 1 : 0)) /
          8) *
          100
      )
    );
  }, [pb.authStore.model]);
  return (
    <Header
      style={{
        width: "100vw",
        zIndex: 1001,
        background:
          (lang || locale) != "en"
            ? "linear-gradient(277deg, rgba(105,220,191,1) 0%, rgba(33,3,63,1) 65%)"
            : "linear-gradient(277deg, rgba(33,3,63,1) 0%, rgba(105,220,191,1) 65%)",
        boxShadow: "0px 2px 0px 0px #DCDCDC",
      }}
      height={HEADER_HEIGHT}
    >
      <Container className={classes.inner}>
        <img
          onClick={() => {
            const records = pb
              .collection("applicant_view_programs")
              .getFullList({});
          }}
          src="/white_text_logo.png"
          style={{
            height: width >= 1180 ? "100%" : "80%",
            margin: `0px ${width >= 1180 ? "0px" : "10px"}`,
          }}
        />
        <div className={classes.links}></div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0px 35px",
          }}
        >
          <Text
            style={{
              color: "#FFFEFD",
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "600",
              letterSpacing: "0.3px",
              margin: "0px 10px",
            }}
          >
            {tn("Profile complete")}
          </Text>
          {ProgressValue != 30 && (
            <RingProgress
              size={60}
              rootColor="#351e4c"
              roundCaps
              thickness={6}
              sections={[{ value: ProgressValue, color: "#69DCBF" }]}
              label={
                <Center>
                  <Text
                    style={{
                      color: "#69DCBF",
                      textAlign: "center",
                      fontSize: "10px",
                      fontWeight: "600",
                    }}
                  >
                    {ProgressValue}%
                  </Text>
                </Center>
              }
            />
          )}
        <NotiPopup/>
        </div>
      </Container>
    </Header>
  );
}
