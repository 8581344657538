import { TextInput, Accordion, SegmentedControl, Select } from "@mantine/core";
import React from "react";
import { main_input_style } from "../../../components_styles";
import { Icon } from "@iconify/react/dist/iconify.js";
import { vf } from "../../../main_components/Functions";

export const AcademicInformation = React.memo(({lang, form, tn, setGPAfirst ,GPAfirst,setMaxGPA }:any) => {
    const case_studies = vf("case_study");
    const universities = vf("university");
    const majors = vf("major");
    const semesters = vf("semester");
    const degrees = vf("degree");
    const graduation_years = vf("graduation_year");
    return (
      <Accordion
          chevron={<Icon icon="ep:arrow-down-bold" className="" />}
          styles={{
            label: {
              fontSize: "25px",
            },
            chevron: {
              color: "#21033F",
            },
          }}
          defaultValue="Academic information"
          className="mt-3"
        >
          <Accordion.Item
            key={"Academic information"}
            value={"Academic information"}
          >
            <Accordion.Control>{tn("Academic information",lang)}</Accordion.Control>
            <Accordion.Panel>
              <div className="w-full mt-10 flex items-center justify-between gap-6 max-md:flex-col">
                <Select
                  style={{ width: "100%" }}
                  label={tn("Case Study",lang)}
                  searchable
                  data={case_studies?.map((option: any) => option?.[lang])}
                  styles={main_input_style(false)}
                  {...form.getInputProps("case_study")}
                />

                <Select
                  style={{ width: "100%" }}
                  label={tn("University",lang)}
                  searchable
                  data={universities?.map((option: any) => option?.[lang])}
                  styles={main_input_style(false)}
                  {...form.getInputProps("university")}
                />
              </div>
              <div className="w-full mt-10 flex items-center justify-between gap-6 ">
                <Select
                  style={{ width: "100%" }}
                  label={tn("Degree",lang)}
                  searchable
                  data={degrees?.map((option: any) => option?.[lang])}
                  styles={main_input_style(false)}
                  {...form.getInputProps("degree")}
                />

                <Select
                  label={tn("Major",lang)}
                  style={{ width: "100%" }}
                  searchable
                  data={majors?.map((option: any) => option?.[lang])}
                  styles={main_input_style(false)}
                  {...form.getInputProps("major")}
                />
              </div>
              <div className="w-full mt-10 flex items-center justify-between gap-6 max-md:flex-col">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                  }}
                >
                  <TextInput
                    label={tn("GPA",lang)}
                    styles={main_input_style(false)}
                    mr="sm"
                    error={false}
                    onBlur={(e) => {
                      if (!form?.values?.GPA) {
                        setGPAfirst("");
                      }
                    }}
                    onChange={(e) => {
                      setGPAfirst(e.target.value);
                    }}
                    value={GPAfirst}
                    style={{ width: "calc(100% - 10px)" }}
                    withAsterisk={false}
                  />
                  <div
                    style={{
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      height: "30px",
                      marginBottom: "7px",
                    }}
                  >
                    <SegmentedControl
                      onChange={(value) => {
                        setMaxGPA(parseInt(value));
                        form.setFieldValue("GPA_out_of", value);
                      }}
                      defaultValue={form?.values?.GPA_out_of}
                      styles={() => ({
                        root: {
                          color: "#737373",
                          backgroundColor: "transparent",
                          border: "1px solid rgba(7, 1, 13, 0.50)",
                          borderRadius: "7px",
                        },
                        label: {
                          color: "#737373",
                          height: "25px",
                          borderRadius: "7px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0px 10px",
                          "&:hover": {
                            color: "#737373",
                          },
                          '&[data-active="true"]': {
                            color: "#ffffff",
                          },
                          "&[data-active]:hover": {
                            color: "#ffffff",
                          },
                        },
                        indicator: {
                          color: "#737373",
                          backgroundColor: (form.values.cv_color || "#21033F") +
                          " !important",
                          borderRadius: "7px",
                        },
                        control: {
                          width: "100%",
                          color: "#737373",
                          //    backgroundColor: "#efefef5f !important",
                          borderRadius: "7px",
                        },
                        controlActive: { color: "#737373" },
                      })}
                      data={[
                        {
                          label: lang == "en" ? "5" : "٥",
                          value: "5",
                        },
                        {
                          label: lang == "en" ? "4" : "٤",
                          value: "4",
                        },
                      ]}
                    />
                  </div>
                </div>
                <Select
                  label={tn("Graduation semester",lang)}
                  style={{ width: "100%" }}
                  searchable
                  data={semesters?.map((option: any) => option?.[lang])}
                  styles={main_input_style(false)}
                  {...form.getInputProps("semester")}
                />
              </div>
              <div className="w-full mt-10 flex items-center justify-between gap-6 ">
                <Select
                  label={tn("Graduation Year",lang)}
                  style={{ width: "100%" }}
                  searchable
                  data={
                    graduation_years?.[0]
                      ? graduation_years?.map((option: any) => option?.[lang])
                      : []
                  }
                  styles={main_input_style(false)}
                  {...form.getInputProps("graduation_year")}
                />

                <TextInput
                  label={tn("University number",lang)}
                  style={{ width: "100%" }}
                  styles={main_input_style(false)}
                  {...form.getInputProps("university_number")}
                />
              </div>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
    );
  });
 