import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import { useEffect, useState } from "react";
import { ActionIcon, Button, TextInput } from "@mantine/core";
import { Icon } from "@iconify/react/dist/iconify.js";
import { AIautofill } from "./main_components/ai_autofill/AIautofill";

export const main_input_style = (error: any, style: any = {}) => {
  return {
    ...style,
    label: {
      color: error ? "rgba(215, 78, 32, 0.50)" : "#454049",
      fontSize: "16px",
      marginBottom: "10px",

      ...style.label,
    },
    input: {
      borderRadius: "15px",
      border: error ? "1px solid #F9837C" : "0px solid #5BB9BF",
      backgroundColor: error ? "rgba(215, 78, 32, 0.15)" : "#F6F5F7",
      height: "45px",
      color: "#3b3b3b",
      fontSize: "14px",
      ...style.input,
    },
    error: {
      color: "rgba(215, 78, 32, 0.50)",
      marginBottom: "-16px !important",
      ...style.error,
    },
  };
};
export const main_no_input_style = () => {
  return {
    borderRadius: "15px",
    backgroundColor: "#F6F5F7",
    height: "45px",
    color: "#3b3b3b",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
  };
};
export const main_view_style = () => {
  return {
    borderRadius: "15px",
    backgroundColor: "#F6F5F7",
    height: "45px",
    color: "#3b3b3b",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
  };
};

export const buttons_style = (type: any) => {
  switch (type) {
    case "light-red":
      return {
        root: {
          borderRadius: "15px",
          backgroundColor: "#FEF3F2",
          color: "#F9837C",
          "&:hover": {
            backgroundColor: "#ffe2df",
            color: "#F9837C",
          },
        },
      };
    case "light-green":
      return {
        root: {
          borderRadius: "15px",
          backgroundColor: "#69dcbf19",
          color: "#69DCBF",
          "&:hover": {
            backgroundColor: "#69dcbf35",
            color: "#69DCBF",
          },
        },
      };
    case "filled-green":
      return {
        root: {
          borderRadius: "15px",
          backgroundColor: "#69DCBF",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#5cd1b3",
            color: "#ffffff",
          },
        },
      };
    case "filled-main":
      return {
        root: {
          borderRadius: "15px",
          backgroundColor: "#21033F",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#260943",
            color: "#ffffff",
          },
        },
      };
    case "outline-red":
      return {
        root: {
          borderRadius: "15px",
          backgroundColor: "transparent",
          color: "#F9837C",
          border: "1px solid #F9837C",
          "&:hover": {
            backgroundColor: "#f9827c1a",
            color: "#F9837C",
          },
        },
      };
      case "outline-main":
        return {
          root: {
            borderRadius: "15px",
            backgroundColor: "transparent",
            color: "#21033F",
            border: "1px solid #21033F",
            "&:hover": {
              backgroundColor: "#21033F1a",
              color: "#21033F",
            },
          },
        };
        case "outline-gray":
          return {
            root: {
              borderRadius: "15px",
              backgroundColor: "transparent",
              color: "#464646",
              border: "1px solid #464646",
              "&:hover": {
                backgroundColor: "#4646461a",
                color: "#464646",
              },
            },
          };
    default:
      return {
        root: {},
      };
  }
};

export const RichTextEditorComponent = ({
  // content,
  form,
  name,
  label,
  ai_autofill,lang
}: any) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: getValueByPath(form.values, name),
    onUpdate: ({ editor }) => {
      form.setFieldValue(name, editor.getHTML()); // Sync editor content with the form
    },
  });

  useEffect(() => {
    const newValue = getValueByPath(form.values, name);
    if (editor && newValue !== editor.getHTML()) {
      editor.commands.setContent(newValue || ""); // Update the editor content with the new value
    }
  }, [form.values, name, editor]);
  return (
    <div>
      <div
        className="w-full mt-6
  mb-2 text-[#454049] text-[16px]  font-medium flex gap-2 items-center justify-between
  "
      >
       <div style={{
        minWidth: "fit-content",
       }}>
       {label}
       </div>
       <AIautofill ai_autofill={ai_autofill} lang={lang} user_data={
          form?.values
       }
       old_text={getValueByPath(form.values, name)}
       form={form}
       name={name}
       />
      </div>
      <div className="border border-[#21033F33] w-full rounded-[15px] font-w">
        <RichTextEditor
          styles={{
            root: {
              backgroundColor: "white",
              border: "0px solid #e1e1e1",
              borderRadius: "16px",
            },
            content: {
              paddingBottom: "10px",
              backgroundColor: "#F6F5F7",
              borderRadius: "0px 0px 16px 16px",
            },
            toolbar: {
              borderRadius: "16px 16px 0px 0px",
              top: "0",
            },
          }}
          editor={editor}
        >
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Highlight />
              <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
              <RichTextEditor.Subscript />
              <RichTextEditor.Superscript />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignJustify />
              <RichTextEditor.AlignRight />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content />
        </RichTextEditor>
      </div>
    </div>
  );
};

function getValueByPath(obj, path) {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}
