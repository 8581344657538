import React from 'react';
import { tn } from '../../../translation';

export const CVtemplate1 = ({ data,lang }: any) => {
  return (
    <div    dir={lang === "ar" ? "rtl" : "ltr"}
      style={{
        fontFamily: data?.font_type+", sans-serif" ,
      }}
    >
      {/* Header Bar */}
      <div
        style={{
          border: `5px solid ${data?.cv_color || '#f97316'}`,
        }}
      />

      {/* Profile Section */}
      <div style={{ padding: '20px', color: '#333' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            marginBottom: '30px',
          }}
        >
          {data?.profile_picture && (
            <div   style={{
              borderRadius: '50%',
              width: '100px',
              minWidth: '100px',

              height: '100px',
              border: `4px solid ${data?.cv_color || '#f97316'}`,
            }}>
            <img
              src={
                typeof data?.profile_picture == "string"
                  ? data?.profile_picture
                  : URL?.createObjectURL(data?.profile_picture)
              }
              alt="Profile"
              style={{
                width: '100%',
              }}
            />
            </div>
          )}
          <div>
            <h1
              style={{
                color: data?.cv_color || '#f97316',
                fontWeight: 'bold',
                fontSize: '26px',
              }}
            >
              {data.first_name} {data.last_name}
            </h1>
            <p style={{ fontSize: '16px', color: '#666' }}>{data.about}</p>
          </div>
        </div>

        {/* Contact Info */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <a
            href={`mailto:${data.email}`}
            style={{ fontSize: '14px', color: '#f97316', textDecoration: 'none' }}
          >
            ✉️ {data.email}
          </a>
          <a
            href={`tel:${data.mobile}`}
            style={{ fontSize: '14px', color: '#f97316', textDecoration: 'none' }}
          >
            📞 {data.mobile}
          </a>
        </div>

        {/* Experience Section */}
        <section>
          <h2
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
            }}
          >
            {tn('Experiences', lang)}
          </h2>
          {data.experiences.map((experience, index) => (
            <div key={index} style={{ marginBottom: '15px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3 style={{ color: '#333', fontSize: '16px', fontWeight: 'bold' }}>
                  {experience.job_title}
                </h3>
                <p style={{ fontSize: '12px', color: '#666' }}>
                  {experience.start_date &&
                    new Date(experience.start_date).toLocaleDateString()}{" "}
                  -{" "}
                  {experience.end_end &&
                    new Date(experience.end_end).toLocaleDateString()}
                </p>
              </div>
              <p style={{ fontSize: '14px', margin: '5px 0', color: '#444' }}>
                {experience.company} - {experience.city}, {experience.country}
              </p>
              <div
                dangerouslySetInnerHTML={{ __html: experience.description }}
                style={{ fontSize: '14px', color: '#555' }}
              ></div>
            </div>
          ))}
        </section>

        {/* Education Section */}
        <section>
          <h2
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              marginTop: '20px',
            }}
          >
            {tn('Education', lang)}
          </h2>
          <div>
            <h3 style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>
              {data.university}
            </h3>
            <p style={{ fontSize: '14px', color: '#444' }}>{data.major}</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '12px',
                color: '#666',
              }}
            >
              <p>{tn("University number",lang)} {data.university_number}</p>
              <p> {tn("GPA",lang)}: {data.GPA}/{data.GPA_out_of}</p>
            </div>
          </div>
          {data.education.map((education_, index) => (
            <div key={index} style={{ marginBottom: '15px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3 style={{ color: '#333', fontSize: '16px', fontWeight: 'bold' }}>
                  {education_.university}
                </h3>
                <p style={{ fontSize: '12px', color: '#666' }}>
                  {education_.graduation_date &&
                    new Date(education_.graduation_date).toLocaleDateString()}{" "}
                 
                </p>
              </div>
              <p style={{ fontSize: '14px', margin: '5px 0', color: '#444' }}>
                {education_.major} - {education_.GPA}
              </p>
            </div>
          ))}
        </section>

        {/* Certificates Section */}
        <section>
          <h2
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              marginTop: '20px',
            }}
          >
            {tn('Certificates and Courses', lang)}
          </h2>
          {data.certificates.map((certificate, index) => (
            <div key={index} style={{ fontSize: '14px', color: '#555' }}>
              <p>
                <strong>{certificate.title}</strong> - {certificate.institute}
              </p>
              <p>{certificate.date && new Date(certificate.date).toLocaleDateString()}</p>
            </div>
          ))}
        </section>

        {/* Skills Section */}
        <section>
          <h2
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              marginTop: '20px',
            }}
          >
            {tn('Skills', lang)}
          </h2>
          <ul style={{ fontSize: '14px', color: '#555' }}>
            {data.skills.map((skill, index) => (
              <li key={index}>
                {skill.skill} - {skill.level}
              </li>
            ))}
          </ul>
        </section>

        {/* Languages Section */}
        <section>
          <h2
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              marginTop: '20px',
            }}
          >
            {tn('Languages', lang)}
          </h2>
          <ul style={{ fontSize: '14px', color: '#555' }}>
            {data.languages.map((language, index) => (
              <li key={index}>
                {language.language} - {language.level}
              </li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
};
